import React, { Suspense, lazy } from 'react'

import { Route, Routes, BrowserRouter } from 'react-router-dom'

import Layout from './components/Containers/Layout/Layout'
import PageLoader from './components/UI/PageLoader/PageLoader'

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const CheckoutLazy = lazy(() => import('./components/Pages/Checkout/Checkout'))
const PrivacyLazy = lazy(() => import('./components/Pages/Privacy/Privacy'))
const TermsConditionsLazy = lazy(
  () => import('./components/Pages/TermsConditions/TermsConditions')
)
const DisclaimerLazy = lazy(
  () => import('./components/Pages/Disclaimer/Disclaimer')
)

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<HomeLazy />} />
            <Route path="checkout/:tier" element={<CheckoutLazy />} />
            <Route path="privacy-policy" element={<PrivacyLazy />} />
            <Route path="disclaimer" element={<DisclaimerLazy />} />
            <Route
              path="terms-and-conditions"
              element={<TermsConditionsLazy />}
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
