import React from 'react'

import css from './PageLoader.module.scss'
import Bear from '../../../assets/bear.svg'

const PageLoader = () => (
  <div className={css.LoaderBkg}>
    <div className={css.Loader}></div>
    <img src={Bear} className={css.Logo} alt="pipem.io" />
  </div>
)

export default PageLoader
