import React, { useEffect, Fragment } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Background from './Background/Background'

import css from './Layout.module.scss'

const Layout = () => {
  let location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Fragment>
      <main className={css.Main}>
        <Outlet />
      </main>
      <Background />
    </Fragment>
  )
}

export default Layout
